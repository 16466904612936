<template>
  <b-sidebar
    id="create-university-sidebar"
    hide-footer
    right 
    ref="createUniversitySidebar"
    bg-variant="white"
    backdrop
  >
    <div class="p-2">
      <b-form>
        <h2 class="m-0 text-colorBlack">Add University</h2>
        <b-form-row class="py-1">
          <b-col>
            <span class="font-weight-bolder text-colorBlack">University Name</span>
            <b-form-group class="mb-0">
              <b-form-input
                id="universityName"
                placeholder="University Name"
                v-model="universityName"
              />
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row class="py-1">
          <b-col>
            <span class="font-weight-bolder text-colorBlack"
              >Contact Person First Name</span
            >
            <b-form-group class="mb-0">
              <b-form-input
                id="contactPersonFirstName"
                placeholder="Contact Person First Name"
                v-model="contactPersonFirstName"
              />
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row class="py-1">
          <b-col>
            <span class="font-weight-bolder text-colorBlack"
              >Contact Person Last Name</span
            >
            <b-form-group class="mb-0">
              <b-form-input
                id="contactPersonLastName"
                placeholder="Contact Person Last Name"
                v-model="contactPersonLastName"
              />
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row class="py-1">
          <b-col>
            <span class="font-weight-bolder text-colorBlack"
              >Contact Person Email</span
            >
            <b-form-group class="mb-0">
              <b-form-input
                id="contactPersonEmail"
                placeholder="Contact Person Email"
                v-model="contactPersonEmail"
                type="email"
              />
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row class="py-1">
          <b-col>
            <span class="font-weight-bolder text-colorBlack"
              >Contact Person Phone No</span
            >
            <b-form-group class="mb-0">
              <b-form-input
                id="contactPersonPhoneNo"
                placeholder="Contact Person Phone No"
                v-model="contactPersonPhoneNo"
                type="number"
              />
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row class="py-1">
          <b-col>
            <span class="font-weight-bolder text-colorBlack"
              >School Address</span
            >
            <b-form-group class="mb-0">
              <b-form-input
                id="schoolAddress"
                placeholder="School Address"
                v-model="schoolAddress"
              />
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row class="py-1">
          <b-col>
            <span class="font-weight-bolder text-colorBlack"
              >Branch Name (if any)</span
            >
            <b-form-group class="mb-0">
              <b-form-input
                id="branchName"
                placeholder="Branch Name "
                v-model="branchName"
              />
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row class="py-1">
          <b-col>
            <span class="font-weight-bolder text-colorBlack"
              >No of Students</span
            >
            <b-form-group class="mb-0">
              <v-select label="name" v-model="no_of_students" placeholder="Select Option" :options="students_option" :reduce="option => option.id" autoscroll :clearable="true"></v-select>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row class="py-1">
          <b-col>
            <span class="font-weight-bolder text-colorBlack"
              >No of Teacher</span
            >
            <b-form-group class="mb-0">
              <v-select label="name" v-model="no_of_teachers" placeholder="Select Option" :options="teachers_option" :reduce="option => option.id" autoscroll :clearable="true"></v-select>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row class="py-1">
          <b-col>
            <span class="font-weight-bolder text-colorBlack"
              >Vat ID</span
            >
            <b-form-group class="mb-0">
              <b-form-input
                id="vatId"
                placeholder="Vat ID"
                v-model="vatId"
              />
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row class="py-1">
          <b-col>
            <span class="font-weight-bolder text-colorBlack"
              >Vat %</span
            >
            <b-form-group class="mb-0">
              <b-form-input
                id="vatPercentage"
                placeholder="Vat %"
                v-model="vatPercentage"
              />
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row class="py-1">
          <b-col>
            <span class="font-weight-bolder text-colorBlack"
              >Organization Number</span
            >
            <b-form-group class="mb-0">
              <b-form-input
                id="organizationNumber"
                placeholder="Organization Number"
                v-model="organizationNumber"
              />
            </b-form-group>
          </b-col>
        </b-form-row>


        <b-form-row class="py-1">
          <b-col>
            <span class="font-weight-bolder text-colorBlack"
              >Upload Picture</span
            >
            <b-form-group class="mb-0">
              <vue-dropzone
                ref="myVueDropzone"
                id="dropzone"
                :options="dropzoneOptions"
                :useCustomSlot="true"
                class="bg-colorLightBlue"
                @vdropzone-file-added="handleFileAdded"
              >
                <div class="dropzone-custom-content">
                  <b-img
                    :src="
                      require('@/assets/images/icons/instructor/assignment/upload.svg')
                    "
                  />
                  <h3
                    class="dropzone-custom-title text-colorBlue mt-1 font-weight-bolder"
                  >
                    Upload Organization Logo
                  </h3>
                  <div class="subtitle">
                    <p>Click browse thorough your machine</p>
                  </div>
                </div>
              </vue-dropzone>
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-form>

      <b-form-group>
        <b-button
          block
          type="submit"
          variant="colorBlue"
          pill
          @click="submit"
        >
          Add
        </b-button>
      </b-form-group>
    </div>
  </b-sidebar>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import util from "@/util.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    vueDropzone: vue2Dropzone,
  },
  props: {},
  mixins: [util],
  data() {
    return {
      universityName: "",
      contactPersonFirstName: "",
      contactPersonLastName: "",
      contactPersonEmail: "",
      contactPersonPhoneNo: "",
      organizationNumber: "",
      schoolAddress: "",
      branchName: "",
      no_of_students: "",
      no_of_teachers: "",
      vatId: "",
      vatPercentage: "",
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        maxFiles: 1,
        headers: { "My-Awesome-Header": "header value" },
      },
      image: null,
      students_option: [
        { name: "1-100", id: "1-100" },
        { name: "101-500", id: "101-500" },
        { name: "501-1000", id: "501-1000" },
        { name: "1001-5000", id: "1001-5000" },
        { name: "5001-10000", id: "5001-10000" },
        { name: "10000+", id: "10000+" },
      ],
      teachers_option: [
        { name: "1-10", id: "1-10" },
        { name: "11-30", id: "11-30" },
        { name: "31-50", id: "31-50" },
        { name: "51-100", id: "51-100" },
        { name: "100+", id: "100+" },
      ]
    };
  },
  async mounted() {},
  methods: {
    ...mapActions({
      createUniversity: "appData/createSchool",
    }),

    handleFileAdded(file) {
      this.image = file;
    },

    async submit() {
      try {
        const formData = new FormData();
        formData.append("name", this.universityName);
        formData.append("contact_person_first_name", this.contactPersonFirstName);
        formData.append("contact_person_last_name", this.contactPersonLastName);
        formData.append("contact_person_email", this.contactPersonEmail);
        formData.append("contact_person_phone", this.contactPersonPhoneNo);
        formData.append("institute_type", 'University');
        formData.append("address", this.schoolAddress);
        formData.append("branch_name", this.branchName);
        formData.append("no_of_students", this.no_of_students);
        formData.append("no_of_teachers", this.no_of_teachers);
        formData.append("vat_id", this.vatId);
        formData.append("vat_percentage", this.vatPercentage);
        formData.append("organization_number", this.organizationNumber);
        formData.append("city", this.getLoggedInUser.city);
        if (this.image) {
          formData.append("image", this.image); 
        }
        formData.append("created_by", this.getLoggedInUser.id); 
        formData.append("updated_by", this.getLoggedInUser.id); 

        const res = await this.createUniversity(formData);
        if (res.status === 201) {
          this.$swal({
            title: "University created successfully",
            icon: "success",
          });
          this.reset();
          this.$nextTick(() => {
            this.$refs.createUniversitySidebar.hide();
          });
          this.$emit("modalClosed");
        }
      } catch (error) {
        this.displayError(error);
      }
    },

    reset() {
      this.universityName = "";
      this.contactPersonFirstName = "";
      this.contactPersonLastName = "";
      this.contactPersonEmail = "";
      this.contactPersonPhoneNo = "";
      this.image = null;
      this.$refs.myVueDropzone.removeAllFiles();
    },
  },
  computed: {
    ...mapGetters({ getLoggedInUser: "appData/getLoggedInUser" }),
  },
};
</script>
